<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item" @click="openRegister()">
                <b class="change-login-register-action">Iniciar sesión</b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">
              Cerrar sesión de usuario
            </p>
          </div>
          <div class="column is-half login-form-container">
            <b-field
              label="Email"
              class="login-form-field"
              :type="{ 'is-danger': error.email }"
              :message="{ 'El correo no es válido': error.email }"
            >
              <b-input
                placeholder="Ejemplo: nombre@mail.com"
                v-model="loginUser.email"
              ></b-input>
            </b-field>

            <b-field
              label="Contraseña"
              class="login-form-field"
              :type="{ 'is-danger': error.password }"
              :message="{ 'La contraseña es incorrecta': error.password }"
            >
              <b-input
                placeholder="contraseña"
                type="password"
                maxlength="35"
                v-model="loginUser.password"
                password-reveal
              ></b-input>
            </b-field>
          </div>
          <div class="column is-half login-button">
            <b-button class="login-button" @click="logoutSpecificUser()"
              >Cerrar sesión de usuario</b-button
            >
          </div>
          <div class="divider-container">
            <hr class="solid-divider" />
          </div>
          <div class="change-login-register-container">
            <p class="change-login-register-action" @click="openLogin()">
              ¿Ya tienes una cuenta? Puedes iniciar sesión con el nombre de tu
              empresa y tu correo
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs utilities-list">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Almacenes</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Control de artículos</a></li>
            <li><a>Compras</a></li>
            <li><a>Control de clientes</a></li>
            <li><a>Venta</a></li>
            <li><a>Bancos</a></li>
            <li><a>Tesorería</a></li>
            <li><a>Calendario</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import SecureLS from "secure-ls";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
// @ is an alias to /src
export default {
  name: "LogoutSpecificUser",
  components: {},
  data() {
    return {
      loginUser: {
        email: "",
        password: "",
      },
      error: {
        email: false,
        password: false,
      },
      rememberMe: false,
    };
  },
  mounted() {
    const ls = new SecureLS({ isCompression: false });
    let currentUserRememberMe = ls.get("ERPFCBUserRememberMe");
    if (currentUserRememberMe) {
      this.loginUser.email = JSON.parse(currentUserRememberMe).email;
      this.loginUser.companyMainName = JSON.parse(
        currentUserRememberMe
      ).companyMainName;
    }
  },
  methods: {
    openLogin() {
      this.$router.push({ path: "/Login" });
    },
    async logoutSpecificUser() {
      try {
        this.error = validateFields(this.loginUser, this.error);
        if (checkValidationErrors(this.error)) {
          const response = await this.$store.dispatch(
            "LOGOUTSPECIFICUSER",
            this.loginUser
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se cerró exitosamente la sesión del usuario",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.openLogin();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
            this.login(this.loginUser);
          } else if (response === "Wrong Password") {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Contraseña incorrecta",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          } else if (response === "Error user not found") {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "El email es incorrecto",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al cerrar la sesión",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Por favor valide que los datos son correctos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async login(loginUser) {
      this.error = validateFields(this.loginUser, this.error);
      if (checkValidationErrors(this.error)) {
        const response = await this.$store.dispatch("LOGIN", loginUser);
        if (response === "Success") {
          await this.$store.dispatch("GETCOMPANYINFORMATION");
          this.$router.push("/Dashboards/Initial").catch(() => {});
          let allPromises = [];

          allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
          allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
          allPromises.push(this.$store.dispatch("GETARTICLES"));
          allPromises.push(this.$store.dispatch("GETPROVIDERS"));
          allPromises.push(
            this.$store.dispatch("GETPOSNOSALES", {
              warehouses: [],
            })
          );
          allPromises.push(this.$store.dispatch("GETCLIENTS"));
          allPromises.push(this.$store.dispatch("GETUSERS"));
          allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
          allPromises.push(this.$store.dispatch("GETLASTEXPENSENUMBER"));
          allPromises.push(
            this.$store.dispatch("GETLASTWAREHOUSETRANSFERNUMBER")
          );
          allPromises.push(this.$store.dispatch("GETCOMPANYEMAILS"));
          allPromises.push(this.$store.dispatch("GETLASTPAYMENTNUMBER"));
          allPromises.push(this.$store.dispatch("GETLASTSALENUMBER"));
          allPromises.push(this.$store.dispatch("GETUSERNOTIFICATIONS"));
          allPromises.push(this.$store.dispatch("GETCATEGORIES"));

          Promise.all(allPromises);
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
